import React from "react";
import styled from "styled-components";
import Paragraph from "../components/blog/Paragraph";
import BlogTitle from "../components/blog/BlogTitle";
import ImageWithCaption from "../components/blog/ImageWithCaption";
import BlogHeading2 from "../components/blog/BlogHeading2";
import InternalLink from "../components/blog/InternalLink";
import ExternalLink from "../components/blog/ExternalLink";
import BlockQuote from "../components/blog/BlockQuote";
import UnorderedList from "../components/blog/UnorderedList";
import UnorderedListItem from "../components/blog/UnorderedListItem";
import {StaticImage} from "gatsby-plugin-image";
import SEO from "../components/SEO";
import BlogHeading3 from "../components/blog/BlogHeading3";

const BlogPostWrapper = styled.main`
  padding: var(--main-padding-mob);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMainContent = styled.div`  
  max-width: 750px; 
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const BlogPost = ({location}) =>{
    return <BlogPostWrapper> <StyledMainContent>
        <SEO
            title="Crush Your Keto Diet With This Ultimate Keto Pantry Staples List"
            description="What should you stock up on if you're on the keto diet? Here is a full list of keto pantry staples that will help you breeze through keto."
            location={location}
            pageType="article"
        />

        <HeroSection>
            <BlogTitle>
                Crush Your Keto Diet With This Ultimate Keto Pantry Staples List
            </BlogTitle>
            <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/Crush-Your-Keto-Diet-With-This-Ultimate-Keto-Pantry-Staples-List.jpg" alt="Crush Your Keto Diet With This Ultimate Keto Pantry Staples List"/>
                <em>Photo by <ExternalLink to="https://unsplash.com/es/@micheile?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">micheile dot com</ExternalLink> on <ExternalLink to="https://unsplash.com/s/photos/keto?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
            </ImageWithCaption>
        </HeroSection>
        <Paragraph>
            In 2020, the word “keto” was the most searched for food-related topic on Google, with <ExternalLink to = "https://bolstglobal.com/portfolio-items/global-keto-diet-trends/">over 25.4 million searches</ExternalLink> that year. It remains one of the most popular diets in the past few years, easily overtaking intermittent fasting and the famous Atkins diet.
        </Paragraph>
        <Paragraph>
            There's no denying that people are becoming more conscious of what they eat and how it affects their health and fitness performance. The ketogenic diet or keto diet is one of the many tools that consumers all over the world are reaching for to reduce their weight, control their blood sugar levels, and enjoy a whole host of other health benefits.
        </Paragraph>
        <Paragraph>
            Maybe you're one of them. Maybe you've decided to embark on your own keto journey and are now doing the research necessary to set you off on the best path.
        </Paragraph>
        <Paragraph>
            You've come to the right place.
        </Paragraph>
        <Paragraph>
            In this article, we're going to talk about everything you need to have in your pantry to create delicious keto recipes that will satisfy your hunger and meet your dietary requirements. Let's go!
        </Paragraph>
        <BlogHeading2>
            What is a keto diet?
        </BlogHeading2>
        <Paragraph>
            In its essence, the keto diet is a low carb, high fat diet.
        </Paragraph>
        <Paragraph>
            By dramatically reducing the amount of carbohydrates you eat (your net carbs) to around 20 - 50 grams per day, as opposed to the <ExternalLink to = "https://www.mayoclinic.org/healthy-lifestyle/nutrition-and-healthy-eating/in-depth/carbohydrates/art-20045705">recommended 225 - 325 grams per day</ExternalLink> for a 2000 calories diet, your body enters the state of ketosis.
        </Paragraph>
        <Paragraph>
            Ketosis is a metabolic state where there are high levels of ketones in the body. Ketones are a type of fatty acid that the body uses for fuel instead of carbs.
        </Paragraph>
        <Paragraph>
            In other words, your body starts burning fat for energy instead of burning carbs for energy (what it usually does).
        </Paragraph>
        <Paragraph>
            To effectively follow a keto diet, one must either eliminate or drastically reduce the consumption of the following food groups:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>High-sugar foods:</b> cakes, ice-cream, cookies, candy, sodas, etc.</UnorderedListItem>
            <UnorderedListItem><b>Fruit:</b> all fruit (exceptions can be made for small portions of berries)</UnorderedListItem>
            <UnorderedListItem><b>Grains and starches:</b> wheat, rice (brown rice, white rice, and any in between), pasta, cereal, etc.</UnorderedListItem>
            <UnorderedListItem><b>Alcohol:</b> wine, beer, spirit drinks, mixed drinks, etc.</UnorderedListItem>
            <UnorderedListItem><b>Sugar-free foods:</b> anything labeled as sugar-free (syrups, sweeteners, candies, desserts, etc.)</UnorderedListItem>
            <UnorderedListItem><b>Low-fat or diet products:</b> low-fat mayo, low-fat salad dressings or other condiments</UnorderedListItem>
            <UnorderedListItem><b>Beans and legumes:</b> peas, all types of beans (dried beans and canned beans), chickpeas, lentils, etc. </UnorderedListItem>
            <UnorderedListItem><b>Root vegetables:</b> potato, carrot, parsnip, sweet potato, etc.</UnorderedListItem>
            <UnorderedListItem><b>Certain condiments:</b> barbeque sauce, ketchup, teriyaki sauce, etc.</UnorderedListItem>
            <UnorderedListItem><b>Unhealthy fats:</b> mayonnaise, processed vegetable oils, etc.</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            What are the benefits of a keto diet?
        </BlogHeading2>
        <Paragraph>
            <ExternalLink to = "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3945587/">Studies have shown</ExternalLink> that the ketogenic diet can be an effective tool for weight loss and fat loss. In fact, scientists postulate that a person can lose weight on a keto diet even without counting calories or monitoring food intake due to <ExternalLink to = "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4313585/">how filling the low carb, high fat diet</ExternalLink> can be.
        </Paragraph>
        <Paragraph>
            Given the well-documented link between having excess fat and developing type 2 diabetes, the keto diet indirectly helps to reduce risk of prediabetes, type 2 diabetes, and metabolic syndrome.
        </Paragraph>
        <Paragraph>
            It also <ExternalLink to = "https://pubmed.ncbi.nlm.nih.gov/30289048/">offers an alternative treatment</ExternalLink> to type 2 diabetes that doesn't include as much (or any) drug treatment as other forms of type 2 diabetes therapy.
        </Paragraph>
        <Paragraph>
            The keto diet has also been shown to contribute to the <ExternalLink to = "https://pubmed.ncbi.nlm.nih.gov/23651522/">lowering of blood pressure</ExternalLink> and triglyceride levels.
        </Paragraph>
        <Paragraph>
            <ExternalLink to = "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5842847/">For cancer patients</ExternalLink>, the keto diet can be a promising addition to their standard treatment as it potentially has some antitumor effects.
        </Paragraph>
        <Paragraph>
            Since it promotes mitochondrial function and cellular metabolism, the ketogenic diet also <ExternalLink to = "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6720297/">boosts cognitive performance</ExternalLink> in elderly patients with Alzheimer’s disease.
        </Paragraph>
        <Paragraph>
            This short overview of health benefits for keto dieters is by no means extensive, but it is safe to say that the health advantages of the diet should not be overlooked.
        </Paragraph>
        <Paragraph>
            Let's see how you can start transitioning to a keto diet. 
        </Paragraph>
        <BlogHeading2>
            How to build a keto pantry
        </BlogHeading2>
        <Paragraph>
        Above, we presented you with a list of foods and food products you should avoid while on a keto diet. Now it is time to stock your pantry with all the delicious goodness you <i>can</i> eat.
        </Paragraph>
        <Paragraph>
            One caveat here is that we will omit keto foods that cannot be stored in the pantry. After all, the title of this article is <i>Keto Pantry Staples</i>. We'll be back with a more comprehensive keto foods list in the future.
        </Paragraph>
        <BlogHeading3>
            Meat and seafood
        </BlogHeading3>
        <Paragraph>
            When it comes to keto, there is no better source of protein (and fat) than the close-to-zero-carb meats and seafood. Not all of them can be kept in the pantry, so here is a limited list of pantry-safe meat and seafood products:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Anchovies in olive oil</UnorderedListItem>
            <UnorderedListItem>Canned tuna</UnorderedListItem>
            <UnorderedListItem>Canned salmon</UnorderedListItem>
            <UnorderedListItem>Canned ham</UnorderedListItem>
            <UnorderedListItem>Sardines</UnorderedListItem>
        </UnorderedList>
        <BlockQuote>
            <b>Read more:</b> The <InternalLink to ="/paleo-shopping-list">paleo diet</InternalLink> is another type of diet heavily focused on animal protein. 
        </BlockQuote>
        <BlogHeading3>
            Nuts and seeds
        </BlogHeading3>
        <Paragraph>
            Nuts and seeds are amazing for sprinkling over meat dishes or salads or just as snacks. They are low in carbs and high in fats, making them a perfect choice for keto.
        </Paragraph>
        <Paragraph>
            Additionally, they are <ExternalLink to = "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3257681/">high in fiber</ExternalLink>, meaning they make you feel fuller and contribute to lower overall food intake. 
        </Paragraph>
        <Paragraph>
            Here is a list of nuts and seeds for your keto kitchen pantry:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Almonds</UnorderedListItem>
            <UnorderedListItem>Chia seeds</UnorderedListItem>
            <UnorderedListItem>Flaxseeds</UnorderedListItem>
            <UnorderedListItem>Macadamia nuts</UnorderedListItem>
            <UnorderedListItem>Pecans</UnorderedListItem>
            <UnorderedListItem>Pumpkin seeds</UnorderedListItem>
            <UnorderedListItem>Sunflower seeds</UnorderedListItem>
            <UnorderedListItem>Walnuts</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            And don't forget the nut butters:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Almond butter</UnorderedListItem>
            <UnorderedListItem>Sunflower seed butter</UnorderedListItem>
            <UnorderedListItem>Macadamia nut butter</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            Make sure to read the labels of every product you pick up to ensure it is sugar free.
        </Paragraph>
        <Paragraph>
            <i>But what about peanuts</i>, you may ask. Peanuts (and, by extension, peanut butter) are too high in carbohydrates to be considered keto-friendly. Same goes for pistachios and cashews. 
        </Paragraph>
        <BlockQuote>
            <b>PRO TIP:</b> Add all your keto pantry ingredients to this <InternalLink to="/#download-section">free grocery list app</InternalLink> so you don't forget them next time you're at the store.
        </BlockQuote>
        <BlogHeading3>
            Dry goods
        </BlogHeading3>
        <Paragraph>
            This category is kind of a catch-all for various dry ingredients, such as flours, beverage ingredients, and other foods you can incorporate into your keto routine.
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Coffee</UnorderedListItem>
            <UnorderedListItem>Tea</UnorderedListItem>
            <UnorderedListItem>Almond flour</UnorderedListItem>
            <UnorderedListItem>Coconut flour</UnorderedListItem>
            <UnorderedListItem>Chia flour</UnorderedListItem>
            <UnorderedListItem>Psyllium husk powder</UnorderedListItem>
            <UnorderedListItem>Unsweetened cocoa powder</UnorderedListItem>
            <UnorderedListItem>Unsweetened dry coconut (shredded coconut)</UnorderedListItem>
            <UnorderedListItem>Dark chocolate (with over 70% of cocoa and only in small to moderate amounts)</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Oils and condiments
        </BlogHeading3>
        <Paragraph>
            Fats are an important part of the keto diet. But not all fats are created equal. 
        </Paragraph>
        <Paragraph>
            In order to reap the most health benefits of your diet, you should steer clear of products rich in unhealthy or industrially-added fats, such as canola oil, soybean oil, or vegetable oil. 
        </Paragraph>
        <Paragraph>
            Mayonnaise can also be a point of contention for keto fans. It is generally considered keto-friendly as long as you avoid the kind that is high in sugars. Avocado oil mayo is a good, low-sugar, healthy fat option.
        </Paragraph>
        <Paragraph>
            Here is a list of oils and condiments you could store in your keto pantry:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Coconut oil</UnorderedListItem>
            <UnorderedListItem>Olive oil (extra virgin olive oil is the perfect keto pantry staple)</UnorderedListItem>
            <UnorderedListItem>Avocado oil</UnorderedListItem>
            <UnorderedListItem>Mustard (but not honey mustard or other sweetened variations)</UnorderedListItem>
            <UnorderedListItem>Apple cider vinegar</UnorderedListItem>
            <UnorderedListItem>Wine vinegar (red wine and white wine)</UnorderedListItem>
            <UnorderedListItem>Hot sauce</UnorderedListItem>
            <UnorderedListItem>Salsa</UnorderedListItem>
            <UnorderedListItem>Sugar-free ketchup</UnorderedListItem>
            <UnorderedListItem>Soy sauce</UnorderedListItem>
            <UnorderedListItem>Tamari sauce</UnorderedListItem>
            <UnorderedListItem>Sugar-free salad dressings</UnorderedListItem>
            <UnorderedListItem>Mayonnaise (carefully read the label)</UnorderedListItem>
        </UnorderedList>
        <ImageWithCaption>
            <StaticImage src="../assets/images/blogs/2022-12-keto-pantry-staples-hand-holding-a-white-plate-of-meat-eggs-and-tomatoes.png" alt="Hand holding a white plate of meat eggs and tomatoes"/>
            <em>Photo by <ExternalLink to = "https://unsplash.com/@eduroda?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Eduardo Roda Lopes</ExternalLink> on <ExternalLink to = "https://unsplash.com/s/photos/keto?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
        </ImageWithCaption>
        <BlogHeading3>
            Herbs and spices
        </BlogHeading3>
        <Paragraph>
            Who says keto dishes have to be bland and boring? Spices and herbs make the culinary world spin, and there is no reason for you to give up on all their mouthwatering glory.
        </Paragraph>
        <Paragraph>
            Some spices could be categorized as 'high in carbs', but with the amounts you will be using in your recipes, you have nothing to worry about. 
        </Paragraph>
        <Paragraph>
            For example, garlic powder contains about <ExternalLink to = "https://fdc.nal.usda.gov/fdc-app.html#/food-details/171325/nutrients">7 grams of carbs</ExternalLink> in one tablespoon. It's safe to say that you won't be using an entire tablespoon of garlic powder in whatever meal you're having.
        </Paragraph>
        <Paragraph>
            Nevertheless, here are the best low-carb herbs and spices to use when you're making a keto recipe:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Basil</UnorderedListItem>
            <UnorderedListItem>Bay leaf</UnorderedListItem>
            <UnorderedListItem>Chives</UnorderedListItem>
            <UnorderedListItem>Cilantro</UnorderedListItem>
            <UnorderedListItem>Dill</UnorderedListItem>
            <UnorderedListItem>Oregano</UnorderedListItem>
            <UnorderedListItem>Parsley</UnorderedListItem>
            <UnorderedListItem>Peppermint</UnorderedListItem>
            <UnorderedListItem>Rosemary</UnorderedListItem>
            <UnorderedListItem>Sage</UnorderedListItem>
            <UnorderedListItem>Spearmint</UnorderedListItem>
            <UnorderedListItem>Cinnamon</UnorderedListItem>
            <UnorderedListItem>Coriander seed</UnorderedListItem>
            <UnorderedListItem>Caraway seed</UnorderedListItem>
            <UnorderedListItem>Curry powder</UnorderedListItem>
            <UnorderedListItem>Cayenne pepper</UnorderedListItem>
            <UnorderedListItem>Chili powder</UnorderedListItem>
            <UnorderedListItem>Ground mustard seed</UnorderedListItem>
            <UnorderedListItem>Paprika</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Bonus
        </BlogHeading3>
        <Paragraph>
            Lastly, don't forget to consider any of these useful keto ingredients:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Broths (chicken broth, beef broth, bone broth)</UnorderedListItem>
            <UnorderedListItem>Canned coconut milk</UnorderedListItem>
            <UnorderedListItem>Vanilla extract</UnorderedListItem>
            <UnorderedListItem>Protein powder</UnorderedListItem>
            <UnorderedListItem>Keto-friendly tomato paste (meaning without added sugar)</UnorderedListItem>
            <UnorderedListItem>Keto-friendly diced tomatoes (meaning without added sugar)</UnorderedListItem>
            <UnorderedListItem>Keto friendly snacks such as pork rinds, keto bars, kelp or shirataki noodles, flaxseed crackers, etc.</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            What about sweeteners?
        </BlogHeading3>
        <Paragraph>
            You will notice that we suggested avoiding sugar-free foods. This is because sugar-free usually means that the product contains honey, dates, high fructose corn syrup, agave syrup, maple syrup, or any other sweetener that is high in carbs.
        </Paragraph>
        <Paragraph>
            However, this does not mean that you cannot enjoy a sweet keto snack now and then.
        </Paragraph>
        <Paragraph>
            Here are three low-carb sweeteners to add to your keto meal plan:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Stevia</UnorderedListItem>
            <UnorderedListItem>Erythritol</UnorderedListItem>
            <UnorderedListItem>Monk fruit extract</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Takeaway
        </BlogHeading2>
        <Paragraph>
            It can certainly be tedious, reading the label or googling for carb content on every single thing you want to put into your mouth. But it is unfortunately an integral part of the keto diet.
        </Paragraph>
        <Paragraph>
            We hope that our <InternalLink to = "/pantry-staples-list/">pantry staples</InternalLink> collection will help with this. Every item we named in our keto food list was carefully vetted and checked for carb content. You can rest assured that all of them are A-okay to start your keto journey with.
        </Paragraph>
        <Paragraph>
            Finally, don't forget that an organized shopping experience is essential for diet success. Use a <InternalLink to="/#download-section">shopping list app</InternalLink> to ensure that you don't forget anything at home.
        </Paragraph>
        <Paragraph>
            Good luck on your keto adventure!
        </Paragraph>
    </StyledMainContent>
    </BlogPostWrapper>
}

export default BlogPost;